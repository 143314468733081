/*
* 업무구분: 공통
* 화면 명: MSPBC002M
* 화면 설명: 홈(FC)
*/
<template>
  <ur-page-container title="home" class="msp msp-bc-wrap--bgGray none--tit home_new" :show-title="false" type="page" action-type="none" ref="home">
    <div class="msp_home_top_wrap ty--sticky">
      <div class="top_wrap_info">
        <div class="top_wrap--left" @click="fn_DigitProfile('name')">
          <img v-if="lv_profileImageUrl" :src="lv_profileImageUrl" class="top_wrap--photo" />
          <span class="name">{{fn_GetUserName}}</span><span class="etc">님</span>
        </div>
        <div class="top_wrap--right">
          <div class="time_tooltip" :class="lv_tooltipOpend ? 'on'  : 'off'">
            <mo-icon icon="msp-timer" class="tip-open crTy-white" @click="openToolTip">msp-timer</mo-icon>
            <div class="time_tooltip_inner" ref="tooltipBox">
              <label :class="lv_goodMorning" @click="fn_GetWelcomeType(lv_goodMorning,true)">굿모닝<mo-icon v-if="lv_goodMorning === 'refresh element'" icon="msp-refresh-n" ref="rotateIcon"/></label><!-- class: on/off/refresh -->
              <label :class="lv_success" v-show="lv_welcomeType === 2" @click="fn_GetWelcomeType(lv_success,true)">석세스<mo-icon v-if="lv_success === 'refresh element'" icon="msp-refresh-n" ref="rotateIcon"/></label>
              <mo-icon icon="msp-close-n" class="tip-close" @click="closeToolTip">msp-close</mo-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="msp_home_top_wrap">      
      <div class="full home_sch_area">
        <mo-text-field placeholder="고객 또는 메뉴 검색" @click="fn_MoveCustSearch"/>
        <mo-icon icon="msp-home-mike" @click="fn_MoveCustSearchVoice">msp-home-mike</mo-icon>
      </div>

      <!-- 슬라이딩 배너 영역 -->
      <ur-box-container v-if="lv_homeBannList && lv_homeBannList.length" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-swiper-main mt30">
        <mo-carousel :number-of-page="lv_homeBannList.length" height="auto" :prevButton="false" :nextButton="false" noRotate :swiper-option="swiperOption">
          <template v-for="(item, idx) in lv_homeBannList" #[idx+1]>
            <div :key="idx" class="swiper-item" :class="fn_GetBannClass(item)" @click="fn_OnClickBann(item)">
              <div class="txt">
                <div class="fexTy2 txt-tit">
                  <span class="result" v-html="item.bannLTitl"/>
                </div>
                <span v-html="item.bannSTitl"/>
              </div>
              <div class="icon-hand" v-html="fn_GetBannImg(item)"/>
            </div>
          </template>
        </mo-carousel>
      </ur-box-container>      
      <!-- 슬라이딩 배너 영역 //-->
    </div>

      <div v-if="lv_selRntPlanList.length > 0 && lv_rntPlanHide && lv_rntPlanBtnFlg" class="fexTy1 mt80">
        <mo-button class="btn_homemenu--set" @click="fn_isOpenRntPlan('show')">최근설계 보기</mo-button>
      </div>
      <div v-if="!lv_rntPlanBtnFlg" class="mt60 fexTy2 overflow-scroll-x min120">
        <div class="my_work_group mt20 pb10 pl10">
          <div v-if="lv_bioInsrdNm" class="my_work blue pl10" @click="fn_DelHomeBioInfo">
            <div>
              <span class="fs18 fwm">전자서명<br/> 지문촬영</span>
            </div>
            <div class="fexTy4 mt4">
              <span class="fs18 crTy-blue2 round">{{lv_bioInsrdNm}}</span>
            </div>
          </div>
          <div v-if="lv_mobpsIntrsCnt || lv_spcCndtlObjCseCnt" class="my_work ml10">
            <div>
              <span class="fs18 fwb">진행중인 청약</span>
              <span class="ns-tool-tip" v-if="$route.name==='MSPBC002M'">
                <mo-icon id="home-mobps-intrs" icon="msp-question" class="ml10 mt3">msp-question</mo-icon>
                <mo-tooltip target-id="home-mobps-intrs" class="tool-tip-box">
                  &#8251; 모바일청약 중단건은 발행일 +5영업일 이내인 건만 조회됩니다.
                  <br/><br/>
                  &#8251; 미결작성대상은 유효한 건만 조회됩니다.
                </mo-tooltip>
              </span>
            </div>
            <div v-if="lv_mobpsIntrsCnt" class="fexTy3 mt4" @click="$router.push({name: 'MSPPS230M'}).catch(()=>{})">
              <span class="fs16">모바일 청약</span>
              <span class="fs18 fwb crTy-blue2">{{lv_mobpsIntrsCnt}}</span>
            </div>
            <div v-if="lv_spcCndtlObjCseCnt" class="fexTy3" @click="$router.push({name: 'MSPPS260M'}).catch(()=>{})">
              <span class="fs16">미결 작성대상</span>
              <span class="fs18 fwb crTy-blue2">{{lv_spcCndtlObjCseCnt}}</span>
            </div>
          </div>
          <div v-if="lv_pSElstIntrsCseListDVO && lv_pSElstIntrsCseListDVO.length" class="my_work_blue ml10 bgcolor-12">
            <div>
              <span class="fs18rem fwb crTy-red1" @click="$router.push({name: 'MSPPS230M'}).catch(()=>{})">피보험자 동의 필요</span>
              <span class="ns-tool-tip" v-if="$route.name==='MSPBC002M'">
                <mo-icon id="home-spc-cndtl-obj-cse" icon="msp-question" class="ml10 mt3">msp-question</mo-icon>
                <mo-tooltip target-id="home-spc-cndtl-obj-cse" class="tool-tip-box">
                  &#8251; 모바일청약의 피보험자 동의 대상 고객입니다. 
                </mo-tooltip>
              </span>
            </div>
            <div class="fexTy3-3 mt5 blue_group">
              <span v-for="(item, idx) in lv_pSElstIntrsCseListDVO" :key="idx" class="white fs16rem" @click="openMSPPS231P(item)">{{item.insrdNm === ' ' ? item.mnContrNm : item.insrdNm}}</span>
            </div>
          </div>
          <div v-if="lv_selRntPlanList.length > 0 && lv_rntPlanHide && !lv_rntPlanBtnFlg" class="my_work_group pb10">
            <div class="my_work more ml10 mr20" @click="fn_isOpenRntPlan('show')">
              <span>최근설계<br/>보기</span>
            </div>
          </div>
        </div>
        <div v-if="!lv_rntPlanHide" class="my_work_group mt20 pb10">
          <div v-for="(item, idx) in lv_selRntPlanList" :key="idx" class="my_work ml10 fex-clumn fexTy3 w240px" style="min-width: 240px;" @click="$router.push({name: 'MSPPI001M', params: {zaPlanId: item.entplId}}).catch(()=>{})">
            <div>
              <span class="fs18 fwb txtSkip mr20">{{item.planNm}}</span>
            </div>
            <div class="fexTy3 w100 mt4">
              <span class="fs16 txtSkip name w100px">{{item.insrdNm}}</span>
              <span class="fs20 fwb txt-right w100 crTy-blue2">{{$bizUtil.numberWithCommasCurr(item.smtotPrm.amount, item.zaCrncyCd ,'Y','Y', 3)}}</span>
            </div>
          </div>
          <div v-if="lv_selRntPlanList.length" class="my_work more ml10 mr10" @click="$router.push({name: 'MSPPI310M'}).catch(()=>{})">
            <span>최근설계<br/>더보기</span>
            <mo-icon icon="msp-home-more2" class="mt10">msp-home-more2</mo-icon>
          </div>
          <div v-if="lv_selRntPlanList.length" class="my_work more mr20" @click="fn_isOpenRntPlan('hide')">
            <span>최근설계<br/>숨기기</span>
          </div>
        </div>
      </div>
      
      <ur-box-container class="">
        <mo-list :list-data="lv_homMenuList" class="home_menu_group ty--2">
          <template #list-item="{item}">
            <mo-list-item class="home_menu" @on-item-click="fn_OnMoveSrn(item)">
              <span v-if="item.menuNm === '고객알람(고객접촉정보)'" style="flex-direction: column;">
                고객알람
                <span style="font-size: 13px;">(고객접촉정보)</span>
              </span>
              <span v-else>
                {{item.menuNm}}
              </span>
              <mo-badge class="new sm" text="새로운" v-if="item.isNew">새로운</mo-badge><!-- 2024.04.23 뱃지 추가 -->
              <img v-if="item.imgSrc" :src="item.imgSrc" :alt="item.menuNm">
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
      
      <div class="fexTy1 mt20">
        <mo-button class="btn_homemenu--set" @click="fn_OpenMSPBC003P">홈 메뉴 설정</mo-button>
      </div>
      <div class="mt80"></div>

      <!-- 열린화면 S -->
      <div class="right-floating-menu">
        <mo-button class="btn-preview" @click="fn_OpenScreen"><mo-icon icon="msp-preview" class="fs28">msp-preview</mo-icon></mo-button>
        <div class="chat-bubble chat-bubble-top blue" v-if="!lv_ReadOpenScreenTooltip">
          <span ref="aniTxt" class="ani-txt-show">열린 화면을 볼 수 있어요</span>
          <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_CloseOpenScreenTooltip">msp-close-bold</mo-icon>
        </div>
      </div>
      <!-- 열린화면 E -->

      <div class="ns-main-chat" :class="lv_IsShowChatbotMsg ? '' : 'off'" @click.stop="fn_chatBotContent">
        <div class="chat-txt">
          <span>챗봇</span>
        </div>
      </div>

      <MSPBC200P v-if="isReadyHome"/>

      <div v-if="lv_IsLoading" class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
    
      <!--2024.04.01  조회귀점 토스트팝업 start-->
      <div class="home-snackbar__container" v-show="lv_snackbarOpen"> <!-- //2024.04.01 조회귀점 토스트팝업 추가 (변수명은 편하신대로 바꿔사용해주세요) -->
        <div class="home-snackbar" ref="homeSnackbar"> <!-- //on -->
          <div class="home-snackbar__content">
            <span>{{lv_snacbarText}}</span>
          </div>
        </div>
      </div>
      <!--2024.04.01 조회귀점 토스트팝업 end-->
    <!-- <ur-box-container v-if="lv_IsLoading" direction="row" alignV="start" class="home_loading">
      <div class="home_loading-img">
        <img src="@/assets/img/icon/loading_heri.gif" alt="로딩 이미지">
      </div>
    </ur-box-container> -->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MenuConst from '@/ui/sp/MenuConstans.js'
import Msg from '@/systems/webkit/msg/msg'
import MSPBC002P from '@/ui/bc/MSPBC002P.vue'
import MSPBC003P from '@/ui/bc/MSPBC003P.vue'
import MSPBC200P from '@/ui/bc/MSPBC200P.vue'
import MSPPS231P from '@/ui/ps/MSPPS231P'  // 모바일청약 진행현황 팝업
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import bcConstants from '@/config/constants/bcConstants.js'
import moment from 'moment'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import OpenScreenBottomSheet from '@/ui/bc/OpenScreenBottomSheet.vue' // 열린화면 바텀시트
import Promise from '~/src/systems/acl/promise'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC002M',
  screenId: 'MSPBC002M',
  components: {
    MSPBC200P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.host = (['stage','stage2'].includes(process.env.NODE_ENV) && location.host.includes('imss')) ? 'https://itss.samsunglife.com:8410' : ''
    // 귀점 체크
    this.fn_GetWelcomeType(this.lv_initTypeValue,this.lv_clicked)

    // 유틸용 프로필 이미지 로딩
    this.$bizUtil.selDigitProfImg()
    this.fn_OpenFidoRegGuidePopup()
  },
  beforeMount () {
    let pushFlag
    pushFlag = this.getStore('spStore').getters.getState.pushFlag
    if (pushFlag === false) {
      this.$NoticeManager.eventBus.$on(MenuConst.PUSH_ALARM_EVENT, this.fn_PushEventHandler)
      this.getStore('spStore').dispatch('PUSH_SET')
    }
    
    // this.$bcUtil.setInitDataLocalStorage(this)
  },
  mounted() {
    let tmp = this.$route.params
    if (tmp) {
      if (tmp.intentType === 'push') {
        window.vue.getInstance('vue').$NoticeManager.fn_OnNewPushMsgHandler(MenuConst.PUSH_RECEIVE_TYPE_INTENT, tmp.trx)
      }
    }

    // 열린화면 툴팁
    this.lv_ReadOpenScreenTooltip = !!localStorage.getItem('READ_OPENSCREEN_TOOLTIP_MAIN')
  },
  activated() {
    //조회귀점펼치기
    this.lv_tooltipOpend = true
    this.fn_PocDeviceEnv()
    // scroll 위치 복원
    this.fn_ScrollBefore()
    // 귀점 체크
    // this.fn_GetWelcomeType()

    this.getStore('progress').dispatch('NONE')
    this.getStore('notice').dispatch('SET_READY_AGREE', false)
    this.getStore('notice').dispatch('SET_READY_HOME', false)

    if (!this.fn_GetUserName) {
      return
    }

    // 프로필 이미지 조회    
    this.fn_GetProfileImageUrl()
    
    this.lv_IsLoading = true
    Promise.all([
      // 배너 정보 조회
      this.fn_SelListBann(),

      // 전자서명 지문촬영건
      this.fn_SelHomeBioInfo(),

      // 중단건 (모바일청약, 미결작성대상), 모청 피보동의
      this.fn_SelMobpsIntrsCseList(),

      // 최근설계
      this.fn_SelRntPlanList(),

      // 홈메뉴
      !this.lv_homMenuList.length && this.fn_SelListHomMenu(),


    ]).then(response => {
      this.lv_IsLoading = false

      this.fn_SetChatbotMsg(),

      //최근설계 숨기기여부
      this.fn_SelRntPlanHideYn()
    })

    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.fn_SendTodaysData()
    this.fn_HasNewAlarm()

    // FrameNavi 영역 선택 처리
    this.$BottomManager.fn_SetBottomNavigationSelectedMenu('home')

    //업무단에서 등록된 Back key 이벤트 리스너 모두 제거 처리     
    this.$bcUtil.removeAllBackKeyListener()
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
  },
  deactivated() {
    // 챗봇 메시지 노출여부 갱신 - 홈 재진입 시 노출되지 않게 갱신 - push로 진입시는 제외
    if ( !this.lv_IsPush ) {
      let t_IsCompletedChatbotMsg = this.getStore('bcStore').getters.getState.isCompletedChatbotMsg
      if ( t_IsCompletedChatbotMsg === false ) this.getStore('bcStore').dispatch('IS_COMPLETED')
    }

    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    // this.$NoticeManager.eventBus.$off(MenuConst.PUSH_ALARM_EVENT, this.fn_PushEventHandler)

    // scroll 위치 저장
    this.beforeScrollTop = this.$refs.home.beforeScroll
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_IsLoading: false,
      swiperOption: {
        centeredSlides: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        }
      },
      lv_profileImageUrl: '',
      lv_welcomeType: 0,
      lv_homeBannList: [],
      lv_bioInsrdNm: '',
      lv_bioParams: {},
      lv_mobpsIntrsCnt: 0,  // 모바일 청약
      lv_spcCndtlObjCseCnt: 0, //미결작성대상
      lv_pSElstIntrsCseListDVO: [], // 모청피보동의
      lv_selRntPlanList: [], // 최근설계
      lv_homMenuList: [], // 홈메뉴
      lv_rslt: false,
      lv_ModalLogoutConfirm: false,
      lv_MsgLogoutConfirm: '사랑On 앱을 종료하시겠습니까?',
      lv_APDaySchdlListSVO: [],
      lv_IsShowChatbotMsg: false, // 챗봇문구 노출여부
      lv_rntPlanHide: false,
      lv_rntPlanBtnFlg: false,
      lv_rntPlanMoBtnFlg: false,
      lv_rntPlanBioBtnFlg: false,
      lv_ReadOpenScreenTooltip: false, // 열린화면 툴팁 컨트롤
      lv_initTypeValue: 'refresh element',
      lv_goodMorning: 'off',
      lv_success: 'off',
      lv_loginData: '',
      lv_loginWelcomeYn: false,
      lv_loginWelcomeBackYn: false,
      lv_tooltipOpend: false,
      lv_gpsChkYn: '',
      lv_clicked: false,
      lv_lastDegree: 0,
      lv_afterLogin: false,
      lv_snackbarOpen: false,
      lv_setTimeOutVal: '',
      lv_snacbarText: '',
      lv_phoneSnacbarText: '지점/교육장에서 다시 시도해주세요',
      lv_tabletSnacbarText: '사내망 연결 확인 후 다시 로그인해주세요'
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_GetUserName() {
      let userNm = this.getStore('userInfo').getters.getUserInfo.userNm
      window.fdpbridge.exec('AsyncStorageSetPlugin', {key: 'userNm', data: userNm}, function (result) {}, function (result) {})
      return userNm
    },
    isReadyHome() {
      return this.getStore('notice').getters.isReadyHome
    },
  },
  watch: {
    lv_gpsChkYn(newValue, oldValue){
      console.log("@@@@@@@@@@@@  newValue  >>>>"+newValue+"  /   "+ oldValue)
      let userInfo = this.getStore('userInfo').getters.getUserInfo
      if(newValue != ''){
        this.fn_loginInfo(this, userInfo)
        this.lv_gpsChkYn = ''
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명  : rotateRefresh
     * 설명        : 새로고침 표시 로테이션
    ******************************************************************************/
    rotateRefresh() {
      const refreshIcon = this.$refs.rotateIcon
      let degree = this.lv_lastDegree > 0 ? this.lv_lastDegree : 0

      const interval = setInterval(()=> {
        degree = (degree + 1.5) % 360 
        if (degree >= 360) {degree = 0}
        refreshIcon.style.transform = `rotate(${degree}deg)`
        this.lv_lastDegree = degree
      }, 1000 / 360)

      setTimeout(() => {
        clearInterval(interval)
      }, 1000)
    },
    /******************************************************************************
    * Function명  : openToolTip /closeToolTip
    * 설명        : 조회/귀점 표시 활성화 / 비활성화
    ******************************************************************************/
    openToolTip() {
      this.lv_tooltipOpend = true
    },
    closeToolTip() {
      this.lv_tooltipOpend = false
    },
    /******************************************************************************
     * Function명  : fn_PocDeviceEnv
    * 설명        : 앱 사용 디바이스 환경 Tablet / Phone / Other
    ******************************************************************************/
      async fn_PocDeviceEnv () {
        let rslt = await PSCommUtil.fn_GetAppDeviceEnv()
        console.log('fn_PocDeviceEnv rslt = ['+rslt+']')
        let lv_Vm = this
        if (rslt === 'Phone' || localStorage.getItem('osType') === 'ios') {
          lv_Vm.lv_rslt = true
          lv_Vm.fn_SetSrnSize()
        } else {
          lv_Vm.lv_rslt = false
          lv_Vm.fn_SetSrnSize()
        }
        return rslt
      },      
    /*********************************************************
     * Function명: fn_SetSrnSize
     * 설명: 화면크기를 셋팅
     *********************************************************/
    fn_SetSrnSize () {
      let lv_Vm = this
      console.log('fn_SetSrnSize lv_rslt = ['+lv_Vm.lv_rslt+']')
      console.log('fn_SetSrnSize srn_usr_setting = ['+localStorage.getItem('srn_usr_setting')+']')

      if (lv_Vm.lv_rslt === false) {
        if (localStorage.getItem('srn_usr_setting') === 'Y') {
          document.getElementById('page-viewport').content = 'width=600, user-scalable=yes'
        } else if (localStorage.getItem('srn_usr_setting') === 'N') {
          document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
        } else {     
          document.getElementById('page-viewport').content = 'width=600, user-scalable=yes'
          //document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
        }
      } else {
        document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
      }
    },
    /*********************************************************
     * Function명: fn_SelRntPlanHideYn
     * 설명: 최근설계숨기기여부
     *********************************************************/
    fn_SelRntPlanHideYn () {
      let lv_Vm = this
      console.log('rntPlanHideYn = ['+localStorage.getItem('rntPlanHideYn')+']')

      if (localStorage.getItem('rntPlanHideYn') === 'Y') {     
        lv_Vm.lv_rntPlanHide = true // 최근설계 보기가 Y이거나 null이면 최근설계 숨기기가 활성화
        if(lv_Vm.lv_rntPlanMoBtnFlg && lv_Vm.lv_rntPlanBioBtnFlg) {
          lv_Vm.lv_rntPlanBtnFlg = true
        }
      } else {
        lv_Vm.lv_rntPlanHide = false // 최근설계숨기기가 Y이면 최근설계보기가가 활성화
        lv_Vm.lv_rntPlanBtnFlg = false
      }
    },
    /*********************************************************
     * Function명: fn_isOpenRntPlan
     * 설명: 최근설계숨기기 또는 최근설계보기 결정
     *********************************************************/
    fn_isOpenRntPlan(type) {
      let lv_Vm = this
      if (type === 'show') {
        localStorage.setItem('rntPlanHideYn', 'N')
        lv_Vm.lv_rntPlanBtnFlg = false
      } else if (type === 'hide') {
        localStorage.setItem('rntPlanHideYn', 'Y')
        if(lv_Vm.lv_rntPlanMoBtnFlg && lv_Vm.lv_rntPlanBioBtnFlg) {
          lv_Vm.lv_rntPlanBtnFlg = true
        }
      }
      lv_Vm.fn_SelRntPlanHideYn()
    },
    /*********************************************************
     * Function명: fn_GetProfileImage
     * 설명: 프로파일 이미지 url
     *********************************************************/
    fn_GetProfileImageUrl() {
      const userId = this.getStore('userInfo').getters.getUserInfo.userId
      this.lv_profileImageUrl = userId ? (`${this.host}/profimgdown.do?userEno=${userId}&t=${Date.now()}`) : ''
    },
    /*********************************************************
     * Function명: fn_SelListBann
     * 설명: 배너 정보 조회
     *********************************************************/
    fn_SelListBann() {
      const pParams = {}
      const trnstId = 'txTSSBC41S1'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body?.homeBannList) {
          this.lv_homeBannList = response.body.homeBannList
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_GetBannImg(pItem) {
      if (pItem.bannTypeCd === '1') return ''
      const src = (pItem.bannTypeCd === '2' && pItem.bannImgNm) ? `${this.host}/profimgdown.do?bannImg=${pItem.bannImgNm}` : ''
      return src ? `<img src="${src}">` : ''
    },
    fn_GetBannClass(pItem) {
      return pItem.bannTypeCd === '1' ? pItem.bannImgNm === 'A' ? 'home1'
                                      : pItem.bannImgNm === 'B' ? 'home2'
                                      : pItem.bannImgNm === 'C' ? 'home3'
                                      : pItem.bannImgNm === 'D' ? 'home4'
                                      : pItem.bannImgNm === 'E' ? 'home5'
                                      : pItem.bannImgNm === 'F' ? 'home_add'
                                      : pItem.bannImgNm === 'G' ? 'home_open'
                                      : ''
           : pItem.bannTypeCd === '2' ? 'home_self'
           : ''
    },
    /******************************************************************************
    * Function명  : fn_OnClickBann ()
    * 설명        : 관련 화면 이동
    * Params      : pItem
    * Return      : N/A
    ******************************************************************************/
    fn_OnClickBann (pItem) {
      // 관련 페이지로 이동
      if (pItem.bannScCntnt && pItem.bannScCd === '1' ) {
        if (pItem.bannScCntnt.indexOf('tss:') > -1) {
          pItem.srnId = pItem.bannScCntnt
          pItem.tbtMenuPageAttrCd = 'LTS'
          this.$MenuManager.fn_SendIntentAppForMenuItem(pItem)
        } else {
           this.$router.push({name: pItem.bannScCntnt}).catch(()=>{})
        }
      } else {
        pItem.bannScCntnt && pItem.bannScCd === '2' && (
          this.$commonUtil.isMobile()
            ? window.fdpbridge.exec('intentBrowserPlugin', {uri: pItem.bannScCntnt}, () => {}, () => {})
            : window.open(pItem.bannScCntnt, '_blank')
        )
      }
    },
    /******************************************************************************
    * Function명 : fn_OpenFidoRegGuidePopup
    * 설명       : 간편인증 등록 유도 팝업 열기
    ******************************************************************************/
    fn_OpenFidoRegGuidePopup () {
      console.log('fidoRegGuideFlag = ' + localStorage.getItem('fidoRegGuideFlag'))
      // let flag = localStorage.getItem('fidoRegGuideFlag') ? Boolean(localStorage.getItem('userEnoSaveFlag')) : false
      let flag = false
      let monogoYn = 'N'
      if (localStorage.getItem('isShowFidoRegGuidePopup') === 'true' || localStorage.getItem('isShowFidoRegGuidePopup') === true) return
      if (localStorage.getItem('fidoRegGuideFlag') === 'true' || localStorage.getItem('fidoRegGuideFlag') === true) flag = true
      if (localStorage.getItem('user_mongo_yn') === 'Y') monogoYn = 'Y'      

      // 간편인증 미등록
      if (flag && monogoYn === 'Y') {
        let hasResult = false
        this.$bcUtil.openBottomPopUp(this, bcConstants.UNTY_ATHNT_CONFIRM_CASE[4], (isPositive) => {
          !isPositive && !hasResult && this.getStore('notice').dispatch('SET_READY_AGREE', true)
          hasResult = true
        })
        // localStorage.removeItem('fidoRegGuideFlag') // 초기화
      } else {
        // this.$bottomModal.closeAll()
        this.getStore('notice').dispatch('SET_READY_AGREE', true)
      }
    },
    /*********************************************************
     * Function명: fn_DigitProfile
     * 설명: 디지털프로필
     *********************************************************/
    fn_DigitProfile (type) {
      this.$router.push({ name: 'MSPBC100M', params: { type: type } }).catch(()=>{})
    },
    /*********************************************************
     * Function명: fn_SelMobpsIntrsCseList
     * 설명: 중단건 (모바일청약, 미결작성대상), 모청 피보동의
     *********************************************************/
    fn_SelMobpsIntrsCseList() {      
      const pParams = {}
      const trnstId = 'txTSSBC40S1'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        // console.warn(trnstId, response.body);
        if (response?.body) {
          const body = response.body
          this.lv_mobpsIntrsCnt = Number(body.mobpsIntrsCnt) || 0
          this.lv_spcCndtlObjCseCnt = Number(body.spcCndtlObjCseCnt) || 0
          this.lv_pSElstIntrsCseListDVO = body.pSElstIntrsCseListDVO          
          if (this.lv_mobpsIntrsCnt > 0 || this.lv_spcCndtlObjCseCnt > 0 || this.lv_pSElstIntrsCseListDVO.length > 0) {
            this.lv_rntPlanMoBtnFlg = false
          } else {
            this.lv_rntPlanMoBtnFlg = true
          }
        } else {
          this.lv_rntPlanMoBtnFlg = true
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_SelHomeBioInfo
    * 설명        : 전자서명 중단건 지문촬영건 조회
    ******************************************************************************/
    fn_SelHomeBioInfo() {
      const trnstId = 'txTSSPS42S3'
      const auth = 'S'
      const pParams = {}
      return this.post(this, pParams, trnstId, auth).then(response => {
        if (response?.body) {
          const itemData = response.body
          if (itemData.bioSvcId){
            this.lv_bioInsrdNm = itemData.vuhdInfo.insrdNm
            if (this.lv_bioInsrdNm !== null && this.lv_bioInsrdNm !== '') {
              this.lv_rntPlanBioBtnFlg = false
            } else {
              this.lv_rntPlanBioBtnFlg = true
            }
            this.lv_bioParams = {
              svcTp: itemData.bioSvcTp,
              svcId: itemData.bioSvcId,
            }
          } else {
            this.lv_rntPlanBioBtnFlg = true
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_DelHomeBioInfo
    * 설명        : 전자서명피보험자지문촬영요청 삭제
    ******************************************************************************/
    fn_DelHomeBioInfo() {
      const trnstId = 'txTSSPS42U3'
      const auth = 'S'
      const pParams = {}
      this.post(this, pParams, trnstId, auth).then(response => {
        if (response?.body) {
          const isProcOk = response.body.isProcOk
          if (isProcOk === 'Y') { // 삭제 
            console.log('*******삭제상태isProcOk********' + isProcOk)
          } else {
            console.log('*******삭제상태isProcOk********' + isProcOk)
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      }).finally(_ => {
        this.$router.push({name: 'MSPBC315P', params: this.lv_bioParams}).catch(()=>{})
      })
    },
    /*********************************************************
     * Function명: fn_SelRntPlanList
     * 설명: 최근설계
     *********************************************************/
    fn_SelRntPlanList() {
      const pParams = {}
      const trnstId = 'txTSSBC40S2'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        // console.warn(trnstId, response.body);
        if (response?.body?.selRntPlanList) {
          this.lv_selRntPlanList = response.body.selRntPlanList
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명 : fn_고객검색
    * 설명       : 고객검색화면이동
    * 인자       : N/A
    * Return     : void
    ******************************************************************************/
    fn_MoveCustSearch () {
      this.fn_OpenMSPBC002P()
    },
    /******************************************************************************
    * Function명 : fn_고객검색 / 음성인식
    * 설명       : 고객검색화면이동
    * 인자       : N/A
    * Return     : void
    ******************************************************************************/
    fn_MoveCustSearchVoice () {
      let voiceText = ''
      let lv_Vm = this

      // 음성인식 호출
      window.fdpbridge.exec('STTPlugin', {}, function (result) {
        // 성공콜백
        voiceText = result.data
        lv_Vm.fn_OpenMSPBC002P(voiceText)
      }, function (result) {
        // 실패콜백
        // 실패시 메시지 표시 없음
        // this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        // this.getStore('confirm').dispatch('ADD', '음성인식에 실패하였습니다.')
      })
    },
    /**
     * Function명 : fn_OpenMSPBC002P
     * 설명       : 통합검색 팝업 호출
     * @param
     * @return
     */
    fn_OpenMSPBC002P (searchText) {
      this.popupBC002P = this.$bottomModal.open(MSPBC002P, {
        properties: {
          searchVoiceWord: searchText
        },
        listeners: {
          onPopupClose: () => {
            console.log('onPopupClose', this.popupBC002P)
            if ( this.popupBC002P ) {
              this.$bottomModal.close(this.popupBC002P)
              this.popupBC002P = ''
            }
          },
          onSrcMove: (pData) => {
            // 통합검색 화면 close
            this.$bottomModal.close(this.popupBC002P)

            // 화면이동
            if ( pData?.rtnData ) {
               // 최근접속화면정보 저장
              this.$bcUtil.setRntConctMenuInfo(this, pData.rtnData)

              // 화면이동
              this.$MenuManager.fn_RouterPushMenuItem(pData.rtnData)
            }
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    openMSPPS231P(pItem) {
      const pop = this.$bottomModal.open(MSPPS231P, {
        properties: {
          pParntSrnId: this.$options.screenId, // 부모 화면 ID          
          pRowPopupData: {vuchId: pItem.vuchId}
        },
        listeners: { 
          // 대상팝업 onPopupCancel $emit 이벤트명 
          onPopupCancel: (rtnData) => {            
            this.$bottomModal.close(pop) // 모달 닫기   
          },
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose: (rtnData) => {            
            this.$bottomModal.close(pop) // 모달 닫기        
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 백버튼 구현(로그아웃 더블클릭에서 클릭으로 변경)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () {
      if ( this.popupBC002P ) {
        this.$bottomModal.close(this.popupBC002P)
        this.popupBC002P = ''
      } else {
        this.fn_BottomLogoutConfirm()
      }

      /*
      let now = new Date()
      if (now - this.lv_BackBtnClcickedDate <= 1000 ) {
        this.lv_ModalLogoutConfirm = true
        this.lv_BackBtnClcickedDate = now
        
        this.fn_BottomLogoutConfirm()
      } else {
        this.lv_BackBtnClcickedDate = now
      }
      */
    },
    /*********************************************************
     * Function명: fn_OnPositive
     * 설명: APP 종료 확인 시 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnPositive () {
      // fdp-confirm 창을 닫는다. fdp-component.css 에서 수정이 되어야 나타남.
      this.lv_ModalLogoutConfirm = false
      // 처리 루틴 적용 네이티브 앱.
      var lv_Vm = this
      var trnstId = 'txTSSBC15P2'
      var pParam = {
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
        .then(function (response) {
          if (lv_Vm.DEBUG) console.log('DEBUG====', response)
          // 2024.01.15 donghun.yoo 수정 시작
          if ( response?.body ) {          
          // 2024.01.15 donghun.yoo 수정 끝
            // finally 에서 처리
            // window.fdpbridge.exec('exitAppPlugin', {}, () => {}, () => {}) // 앱종료
          } else {
            lv_Vm.fn_BottomAlert(response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        }).finally(function () {
          window.fdpbridge.exec('exitAppPlugin', {}, () => {}, () => {}) // 앱종료
        })
    },
    /*********************************************************
     * Function명: fn_OnNegative
     * 설명: APP 종료 거부시 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnNegative () {
      this.lv_ModalLogoutConfirm = false
      this.lv_BackBtnClcickedDate = 0
    },
    /******************************************************************************
    * Function명 : fn_SendTodaysData
    * 설명       : 오늘 데이터를 보냄
    ******************************************************************************/
    fn_SendTodaysData () {
      var lv_Vm = this
      var trnstId = 'txTSSAP20S3'
      var pParam = {
        userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
        fcSchdlStrYmd: moment(new Date()).format('YYYYMMDD'),
        fcSchdlEndYmd: moment(new Date()).format('YYYYMMDD')
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
        .then(function (response) {
          if (lv_Vm.DEBUG) console.log('DEBUG====', response)
          // 2024.01.15 donghun.yoo 수정 시작
          if ( response?.body ) {
          // 2024.01.15 donghun.yoo 수정 끝
            lv_Vm.lv_APDaySchdlListSVO = response.body.aPSchdlInfoSVO
            lv_Vm.lv_APDaySchdlListSVO.sort((a, b) => {
              if (a.fcSchdlStrYmd < b.fcSchdlStrYmd) return -1
              if (b.fcSchdlStrYmd < a.fcSchdlStrYmd) return 1
              if (a.fcSchdlEndYmd > b.fcSchdlEndYmd) return -1
              if (b.fcSchdlEndYmd > a.fcSchdlEndYmd) return 1
              if (a.fcSchdlStrYmd === b.fcSchdlStrYmd && a.fcSchdlEndYmd === b.fcSchdlEndYmd) {
                if (a.fcSchdlStrHm < b.fcSchdlStrHm) return -1
                if (b.fcSchdlStrHm < a.fcSchdlStrHm) return 1
              }
              if (a.fcSchdlNm < b.fcSchdlNm) return -1
              if (b.fcSchdlNm < a.fcSchdlNm) return 1
            })
            let sendData = lv_Vm.fn_MakeString()
            window.fdpbridge.exec('setAppWidgetDataPlugin', { data: sendData }, () => {}, () => {})
          } else {
            lv_Vm.lv_APDaySchdlListSVO = []
            window.fdpbridge.exec('setAppWidgetDataPlugin', { data: 'null' }, () => {}, () => {})
          }          
        }).catch(function(error) {
          window.vue.error(error)
          lv_Vm.lv_APDaySchdlListSVO = []
          window.fdpbridge.exec('setAppWidgetDataPlugin', { data: 'null' }, () => {}, () => {})
        }).finally(function() {
          lv_Vm.lv_APDaySchdlListSVO = []
          window.fdpbridge.exec('setAppWidgetDataPlugin', { data: 'null' }, () => {}, () => {})
        })
    },
    /******************************************************************************
    * Function명 : fn_MakeString
    * 설명       : 펼쳐진 화면에 나타날 데이터 세팅
    ******************************************************************************/
    fn_MakeString () {
      let ret = ''
      let lv_Vm = this
      for (let i = 0; i < lv_Vm.lv_APDaySchdlListSVO.length; i++) {
        let campaign = lv_Vm.lv_APDaySchdlListSVO[i].cntcObjCmpgnNm === undefined || lv_Vm.lv_APDaySchdlListSVO[i].cntcObjCmpgnNm === '' ? 'null' : lv_Vm.lv_APDaySchdlListSVO[i].cntcObjCmpgnNm
        let tmpLoca = lv_Vm.fn_makeSchedulString('where', lv_Vm.lv_APDaySchdlListSVO[i]) === '' ? '장소 미지정' : lv_Vm.fn_makeSchedulString('where', lv_Vm.lv_APDaySchdlListSVO[i])
        ret = ret + lv_Vm.lv_APDaySchdlListSVO[i].fcSchdlScCd + 'tsssmpA' + lv_Vm.lv_APDaySchdlListSVO[i].fcSchdlNm + 'tsssmpA' + lv_Vm.fn_makeSchedulString('when', lv_Vm.lv_APDaySchdlListSVO[i]) + 'tsssmpA' + tmpLoca + 'tsssmpA' + campaign + 'tsssmpB'
      }
      return ret === '' ? 'null' : ret
    },
    /******************************************************************************
    * Function명 : fn_makeSchedulString
    * 설명       : 일정의 시간 및 만날장소에 대한 문자열 Control
    ******************************************************************************/
    fn_makeSchedulString ( code, data) {
      let ret_val = ''
      let lv_Vm = this
      if ( code === 'where' ) {
        if ( data.fcSchdlPrmsPlcNm !== null && data.fcSchdlPrmsPlcNm !== '' ) {
          ret_val = lv_Vm.$bizUtil.omitStr( data.fcSchdlPrmsPlcNm, 26 )
        }
      } else if ( code === 'when' ) {
        if ( data.fcSchdlStrYmd === data.fcSchdlEndYmd ) {
          if ( data.fcSchdlStrHm === null || data.fcSchdlEndHm === null ) {
            ret_val = '종일'
          } else {
            ret_val = lv_Vm.fn_convertTime(data.fcSchdlStrHm) + ' ~ ' + lv_Vm.fn_convertTime(data.fcSchdlEndHm)
          }
        } else {
          ret_val = data.fcSchdlStrYmd + ' ' + lv_Vm.fn_convertTime(data.fcSchdlStrHm) + ' \n~ ' + data.fcSchdlEndYmd + ' ' + lv_Vm.fn_convertTime(data.fcSchdlEndHm)
        }
      }

      return ret_val
    },
    /*********************************************************
     * Function명: fn_convertTime
     * 설명: 일정 정보를 한글화
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_convertTime ( data ) {
      var ret_val = ''

      if ( data === null || data.length !== 4 ) {
        ret_val = ''
      } else {
        if ( Number( data.substring(0, 2)) < 12 ) {
          ret_val = '오전 ' + data.substring(0, 2) + ':' + data.substring(2, 4)
        } else if ( Number( data.substring(0, 2)) > 12 ) {
          ret_val = '오후 ' + ( Number(data.substring(0, 2)) - 12 ) + ':' + data.substring(2, 4)
        } else {
          ret_val = '오후 ' + '12' + ':' + data.substring(2, 4)
        }
      }

      return ret_val
    },
    /*********************************************************
     * Function명: fn_HasNewAlarm
     * 설명: 새 알림이 있는지 확인하는 메서드 - 태블릿구현시 따라감
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_HasNewAlarm () {
      let lv_Vm = this
      let pParams = {
        iapCnfYn: 'N'
      }
      const trnstId = 'txTSSBC03SA'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let t_NewAlarmCnt = 0
          if ( response && response.body ) {
            t_NewAlarmCnt = response.body.totalCnt
          }

          // Framefooter 알림영역 신규알림 갱신 처리
          lv_Vm.$BottomManager.fn_SetBottomMenuNotiValue('notification', Number(t_NewAlarmCnt))
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명  : fn_PushEventHandler
    * 설명        : Push Event 처리 핸들러
    * Params      : Push 데이터
    ******************************************************************************/
    fn_PushEventHandler (pParams) {
      this.lv_IsPush = true

      if (navigator.userAgent.indexOf("OIC-Agent iOS") > -1) {
        window.fdpbridge.exec('FDPLog.debug', { 'message':'fn_PushEventHandler', 'data':pParams })
      }
      // let t_LogMsg = ''
      let t_AlarmType = ''
      let t_ReciveType = ''
      let t_ReciveTelNo = ''
      let t_ChnlCustId = ''
      let t_CustNm = ''
      // t_LogMsg = `GNB Push Event 감지됨\n${JSON.stringify(pParams)}`

      t_AlarmType = pParams.alarmType
      t_ReciveType = pParams.reciveType
      t_ReciveTelNo = pParams.telNo
      t_ChnlCustId = pParams.chnlCustId
      t_CustNm = pParams.custNm

      // alert('fn_PushEventHandler========>' + pParams.reciveType + ' tel:' + t_ReciveTelNo)
      try {
        // 화면이동 - 아래의 알림일련번호 체크 후 없는경우는 바로 호출되며 있는 경우 읽음처리 후 호출됨
        let t_CallBackFunc = () => {
          // 화면이동전 팝업닫기
          this.$bottomModal.closeAll()

          let tmpObj
          switch (t_AlarmType) {
            case MenuConst.PUSH_ALARM_TYPE_CNTC_CUST: // 통합접촉고객
              tmpObj = pParams
              tmpObj.trtYmd = pParams.occuYmd
              tmpObj.detailOpen = true
              /*
              tmpObj.TabIndex_TSSSP115 = 1
              this.$router.push({name: 'TSSSP115M', params: tmpObj})
              */
              // 통합 접촉고객(MSPCM121M)
              this.$router.push({name: 'MSPCM121M', params: tmpObj}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE: // 시스템 알림
              // 유파트너 공지사항(MSPBC007M)
              this.$router.push({name: 'MSPBC007M', params: pParams}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE: // PUSH 관리자 발송(사랑온 미니)
              // 이동화면 지정 시 해당 화면으로 이동, 그외는 알림목록으로 이동
              let t_TbtMenuPageAttrCd = pParams.tbtMenuPageAttrCd
              let t_SrnId = pParams.srnId
              if ( t_TbtMenuPageAttrCd !== undefined && t_TbtMenuPageAttrCd !== null && t_TbtMenuPageAttrCd !== ''
                && t_SrnId !== undefined && t_SrnId !== null && t_SrnId !== '' ) {
                // 화면이동
                let t_Params = {
                  'tbtMenuPageAttrCd': t_TbtMenuPageAttrCd,
                  'srnId': t_SrnId
                }
                this.$MenuManager.fn_RouterPushMenuItem(t_Params, {})
              } else {
                // 유파트너 공지사항(MSPBC007M)
                this.$router.push({name: 'MSPBC007M', params: pParams}).catch(()=>{})
              }
              break
            case MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE: // 이벤트 알림
              tmpObj = pParams
              // 활동캘린더(MSPAP320M)
              this.$router.push({name: 'MSPAP320M'}).catch(()=>{}) // 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M
              break
            case MenuConst.PUSH_ALARM_TYPE_SCHDL_NOTICE: // 스케쥴 알림.
              tmpObj = pParams
              tmpObj.pSchdlYmd = pParams.pSchdlYmd
              tmpObj.isMSPAP301M = true
              // this.$router.push({name: 'MSPAP301M', params: tmpObj}) // 화면ID변경됨
              this.$router.push({name: 'MSPAP314M', params: tmpObj}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_REQ_BICS_NOTICE: // 태블릿으로 부터 전자서명을 위한 고객 생체인증정보(지문) 찰영 요청 PUSH - 2020/05/14 (모바일생체인증전자서명PJT)
              this.$router.push({name: 'MSPBC315P', params: pParams}).catch(()=>{})
              break              
            case MenuConst.PUSH_ALARM_TYPE_REQ_MOVE_RNOTICE: // 태블릿으로 부터 전자서명을 위한 고객 생체인증정보(지문) 찰영 요청 PUSH - 2020/05/14 (모바일청약 중단건 페이지이동)
              this.$router.push({name: 'MSPPS230M', params: pParams}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE: // 프리미엄 고객사랑서비스 방문결과 미입력 고객수 알림
              // 프리미엄 고객사랑 서비스(MSPCM303M)
              this.$router.push({name: 'MSPCM303M', params: pParams}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_CUST_CNSNT_APRV_NOTICE: // 고객등록(지류) - 지점장승인시, 담당 컨설턴트에게 푸시 알림
              // 정보활용동의현황(MSPCM170M)
              this.$router.push({name: 'MSPCM170M', params: pParams}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_TS_SAVE_NOTICE: // TS신청 (2023.04.03 신형상 SVN추가건)
              // TS목록 조회
              this.$router.push({name: 'MSPRC350M'}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_CMPGN_EVT_NOTICE: // 캠페인고객 이벤트 푸시 알림
              // 캠페인고객(MSPCM160M)
              this.$router.push({name: 'MSPCM160M'}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_CUST_AGREE_COMP_NOTICE: // 비대면 고객동의완료시 담당 컨설턴트에게 푸시 알림
              // 고객카드(MSPCM270M)
              this.$router.push({name: 'MSPCM270M', params: pParams}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_PM_RENT: //  푸시 재가입
              // 재가입대상계약(MSPPM850M)
              this.$router.push({name: 'MSPPM850M'}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_FRST_TM_PREM: // 초회보험료
            case MenuConst.PUSH_ALARM_TYPE_UNDW_CMPLT: // 심사완료
            case MenuConst.PUSH_ALARM_TYPE_PRCS_CNTC: // 보장설계PDF
              this.$router.push({name: 'MSPBC007M', params: pParams}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_GOLDEN_RULE: // 골든룰
              this.$router.push({name: 'MSPAP308M'}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_HOLY_MSG_RESP_NOTICE: // 휴일메시지응답처리
              this.$router.push({name: 'MSPBC009M'}).catch(()=>{})
              break
          } // switch
        }
        // 화면이동 END

        // type에 따라 처리
        if (t_ReciveType === MenuConst.PUSH_RECEIVE_TYPE_EVENT) { // Event 형태 N 뱃지 갱신 처리 , 테블릿 Call 요청도 들어 온다.
          // this.addSystemNotice()
          switch (t_AlarmType) {
            case MenuConst.PUSH_ALARM_TYPE_REQ_CALL_NOTICE: // 태블릿으로 부터 들어온 전화걸기
              // let lv_UserID = ''
              // lv_UserID = this.getStore('userInfo').getters.getUserInfo.userId // '채널고객ID',
              // alert('fn_PushEventHandler !!' + t_ReciveTelNo)
              this.$cmUtil.onPhoneCall(t_ReciveTelNo, t_ChnlCustId, t_CustNm)
              break
            case MenuConst.PUSH_ALARM_TYPE_REQ_SYSCALL_NOTICE: // 태블릿으로 부터 들어온 전화걸기(시스템 문의 전화) 통화시간저장하지 않음.
              window.fdpbridge.exec('CallPlugin', {number: t_ReciveTelNo}, function (result) {
                // 성공콜백
              }, function (result) {
                // 실패콜백 alert(result.data)
                // window.fdpbridge.exec('ToastPlugin', {message: '통화 시간을 받지 못했습니다..'}, () => {}, () => {})
              })
              break
            default:
              // IOS대응 - IOS는 reciveType을 INTENT가 아닌 EVENT로만 준다고 하여 EVENT Type영역에도 INTENT와 동일한 로직 추가
              if (navigator.userAgent.indexOf("OIC-Agent iOS") > -1) {
                if ( pParams.iapMatrSn !== undefined &&  pParams.iapMatrSn !== null && pParams.iapMatrSn !== '' ) { // 알림일련번호 있을 경우 읽음처리(시스템공지는 알림목록 이동 후 처리)
                  let lv_Vm = this
                  let t_pParams = {iapMatrSn: pParams.iapMatrSn}
                  const trnstId = 'txTSSBC03U9'
                  const auth = 'U'
                  this.post(lv_Vm, t_pParams, trnstId, auth)
                    .then(function (response) {
                      // 신규알림건수 조회(FrameFooter 신규알림 관련 갱신)
                      lv_Vm.fn_HasNewAlarm()

                      // 화면이동관련 후행 처리
                      t_CallBackFunc()
                    }).catch(function (error) {
                      window.vue.error(error)
                      // 혹시 오류발생하더라도 화면이동
                      t_CallBackFunc()
                    })
                } else {
                  t_CallBackFunc()
                }
              }
              break
          } // switch
        } else if (t_ReciveType === MenuConst.PUSH_RECEIVE_TYPE_INTENT) { // Intent 형태 상세 Action 처리
          if ( pParams.iapMatrSn !== undefined &&  pParams.iapMatrSn !== null && pParams.iapMatrSn !== '' ) { // 알림일련번호 있을 경우 읽음처리(시스템공지는 알림목록 이동 후 처리)
            let lv_Vm = this
            let t_pParams = {iapMatrSn: pParams.iapMatrSn}
            const trnstId = 'txTSSBC03U9'
            const auth = 'U'
            this.post(lv_Vm, t_pParams, trnstId, auth)
              .then(function (response) {
                // 신규알림건수 조회(FrameFooter 신규알림 관련 갱신)
                lv_Vm.fn_HasNewAlarm()

                // 화면이동관련 후행 처리
                t_CallBackFunc()
              }).catch(function (error) {
                window.vue.error(error)
                // 혹시 오류발생하더라도 화면이동
                t_CallBackFunc()
              })
          } else {
            // 
            t_CallBackFunc()
          }
        }
      } catch (error) {
        // t_LogMsg = `Push Handler Error\n${error}`
      }

      // if (t_LogMsg !== '') alert(t_LogMsg)
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      const pop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(pop)
          },
          onPopupCancel: () => {
            this.$bottomModal.close(pop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(pop)
          }
        }
      })
    },
    /**
     * Function명 : fn_BottomLogoutConfirm
     * 설명       : 로그아웃 관련 메시지를 BottomAlert을 confirm창으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomLogoutConfirm () {
      const pop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'B',
          single: false,
          title: "",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(pop)
            this.fn_OnPositive()
          },
          onPopupCancel: () => {
            this.$bottomModal.close(pop)
            this.fn_OnNegative()
          },
          onPopupClose: () => {
            this.$bottomModal.close(pop)
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_OnMoveSrn
     * 설명: 선택화면으로 이동
     * Params: pItem (화면이동 대상정보)
     * Return: N/A
     *********************************************************/
    fn_OnMoveSrn (pItem) {
      // 최근접속화면정보 저장
      this.$bcUtil.setRntConctMenuInfo(this, pItem)
  
      // 화면이동
      this.$MenuManager.fn_RouterPushMenuItem(pItem)
    },
    /******************************************************************************
    * Function명 : fn_chatBotContent
    * 설명       : 챗봇 링크 브라우저 호출
    *              (accessId : 접속시스템구분값(미니:Mini)/ userId : 로그인한 FC 사번)
    ******************************************************************************/
    fn_chatBotContent () {
      let lv_Url = ''
      // 20220706 [ASR220600878] 사랑On 미니챗봇 스크립트 변경
      // 검증계 https://slimsg.samsunglife.com/messenger/?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC
      // 운영계 https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=사번
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
         lv_Url = 'https://ap1.t.slime2.samsunglife.kr:8449/messenger/fcBot?accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
        // lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else {
        // lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      }
      
      console.log(lv_Url)

      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
    },
    /*********************************************************
     * Function명: fn_SetChatbotMsg
     * 설명: 챗봇 문구 setter - '챗봇 별리가 XXX님을 도와드릴게요' 라는 문구를 5초간 노출 시킴
     *                         최초 홈화면 집입 시에만 노출된다. 노출 후 해당 flag를 vuex에 저장하여
     *                         홈화면 재집입 시에는 노출시키지 않음
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SetChatbotMsg() {
      const t_IsCompletedChatbotMsg = this.getStore('bcStore').getters.getState.isCompletedChatbotMsg
      if ( t_IsCompletedChatbotMsg ) {
        this.lv_IsShowChatbotMsg = false
      } else {
        this.lv_IsShowChatbotMsg = true
        setTimeout(() => {
          this.lv_IsShowChatbotMsg = false
          this.getStore('bcStore').dispatch('IS_COMPLETED')
        }, 5000)
      }
    },
    fn_OpenMSPBC003P() {
      const pop = this.$bottomModal.open(MSPBC003P, {
        properties: {
          savedHomeMenuList: this.lv_homMenuList
        },
        listeners: {
          closed: () => {
            this.$bottomModal.close(pop)
          },
          saved: () => {
            // 홈메뉴
            this.fn_SelListHomMenu(),
            
            this.$bottomModal.close(pop)
          }
        }
      },
      {
        properties: {
          full: true,
          preventTouchClose: true,
          mode: 'right' // 우->좌 open popup (defalut: 하->상)
        }
      })
    },
    /*********************************************************
     * Function명: fn_SelListHomMenu
     * 설명: 홈메뉴
     *********************************************************/
    async fn_SelListHomMenu() {
      const pParams = {}
      const trnstId = 'txTSSBC40S6'
      let newMenuList = []
      try {
        newMenuList = await this.$commonUtil.selMgrCfg(this, {envId: 'MENU_NEW_LABEL_LIST'}) // 메뉴신규라벨목록
      } catch (e) {
        console.log('MSPBC002M, selMgrCfg - error >', e)
      }
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body) {
          const body = response.body
          if (!body.rsltFlag) {
            this.$addSnackbar('오류가 발생했습니다.')
            return
          }
          if (!body.homMenuList) {
            return
          }

          this.lv_homMenuList = body.homMenuList?.filter(item => !!item.menuId).map(item => {
            const imgNum =
                item.srnId === 'MSPCM115M' ? '03' // 전체고객
              : item.srnId === 'MSPCM050M' ? '04' // 고객등록동의
              : item.srnId === 'MSPPI700M' ? '01' // 가입보장설계
              : item.srnId === 'MSPPM902M' ? '02' // 보유계약
              : item.srnId === 'MSPCM317M' ? '11' // 관심고객
              : item.srnId === 'MSPCM160M' ? '07' // 캠페인고객
              : item.srnId === 'MSPCM121M' ? '06' // 고객접촉정보
              : item.srnId === 'MSPCM303M' ? '05' // 프리미엄고객사랑서비스
              : item.srnId === 'MSPPI310M' ? '12' // 최근설계현황
              : item.srnId === 'MSPPS220M' ? '13' // 청약서발행현황
              : item.srnId === 'MSPPS230M' ? '14' // 모바일청약현황
              : item.srnId === 'MSPPS240M' ? '15' // 전자서명현황
              : item.srnId === 'MSPPS250M' ? '16' // 신계약진행현황
              : item.srnId === 'MSPCM322M' ? '10' // 수금인수고객
              : item.srnId === 'MSPPM870M' ? '17' // 유지체크명세
              : item.srnId === 'MSPPM911M' ? '09' // 사고보험금
              : item.srnId === 'MSPAP105M' ? '18' // 활동현황
              : item.srnId === 'MSPAP307M' ? '19' // 주간활동현황
              : item.srnId === 'MSPSA201M' ? '08' // 안내자료
              : item.srnId === 'MSPCT300M' ? '20' // 연금계산기
              : item.srnId === 'MSPGC101M' ? '21' // 내주변 기업체
              : item.srnId === 'MSPAP100M' ? '22' // 이달의 업적
              : item.srnId === 'MSPAP130M' ? '23' // 수수료 세부명세
              : item.srnId === 'MSPAP050M' || item.srnId === 'MSPCM340M' ? '24' // 육성코치활동입력, New 통합조회 시스템
              : item.srnId === 'MSPRC100M' ? '25' // 리크루팅 동의
              : item.srnId === 'MSPRC200M' ? '26' // 전체후보자
              : item.srnId === 'tss:TSSGC106D' ? '27' // KED검색
              : item.srnId === 'tss:TSSGP301M' ? '28' // 단체보유계약조회
              : item.srnId === 'tss:TSSGC202M' || item.srnId === 'MSPCM330M' ? '29' // 단체코드등록, 추가계약 추천고객
              : item.srnId === 'tss:TSSGC301M' ? '30' // 단체일괄동의
              : item.srnId === 'tss:TSSGD001M' ? '31' // 단체청약설계
              : item.srnId === 'MSPAP308M' ? '49' // 활동골든룰
              : ''
            
            if (newMenuList.some(innerItem => item.srnId.indexOf(innerItem.envCntnt) > -1)) {
              item.isNew = true
            }

            imgNum && (item.imgSrc = require(`@/assets/img/icon/home_icon/home_menu${imgNum}.png`))
            return item
          })
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /*********************************************************
     * Function명: fn_GetWelcomeType
     * 설명: 귀점 체크
     *********************************************************/
    async fn_GetWelcomeType(typeValue,clicked) {
      if (typeValue !== 'refresh element') return
      
      console.log("@@@@@@@@@@@@@@@@ fn_GetWelcomeType @@@@@@@@@@@")
      this.lv_clicked = clicked
      let lv_Vm = this
      let userInfo = this.getStore('userInfo').getters.getUserInfo
      let currentTime = ''
      /*********************************************************
      * 설명: 서버 시간 추출
      *********************************************************/
      await this.$commonUtil.getServerTime().then(response => {
        if (!response) return
        currentTime = response.substr(11, 5) // hh:mm
        console.log("@@@@@@@@@@  time  >> "+currentTime)
      })
      
      lv_Vm.lv_welcomeType = ('00:00' <= currentTime && currentTime <= '10:30') ? 1
                          : ('16:00' <= currentTime && currentTime <= '23:59') ? 2
                          : 0    

      let promiseGps =  await this.fn_GpsInfo(lv_Vm, userInfo)
      console.log("@@@@@@@@@@  promiseGps  >>>>> "+promiseGps);
      let promiseLogin =  await this.fn_loginInfo(lv_Vm, userInfo)
      console.log("@@@@@@@@@@  promiseLogin  >>>>> "+promiseLogin);
      
    },
    fn_GpsInfo(lv_Vm, userInfo){
      return new Promise((resolve, reject) =>{
        if(localStorage.getItem('deviceType') == 'Phone' && (lv_Vm.lv_welcomeType == 1 || lv_Vm.lv_welcomeType == 2)){
          /*********************************************************
          * 설명: gps정보 유무
          *********************************************************/
          window.fdpbridge.exec('getOfficeCheckinCheckoutPlugin', {},(lv_success) => {                          
            let jsonObj = JSON.parse(lv_success.data)
            console.log('   @@@@@@ 조회/귀점 @@@@@')
            console.log('   @@@@@@ [getOfficeCheckinCheckoutPlugin]')
            console.log('   @@@@@@ myLat @@@@', jsonObj.myLat)
            console.log('   @@@@@@ myLng @@@@', jsonObj.myLng)
          
            if(jsonObj.myLat != '' && jsonObj.myLng != ''){
              console.log('@@@@ gpsData @@@@@', jsonObj.myLat+ "  / "+ jsonObj.myLng)
              /*********************************************************
              * 설명: 지점 간 거리 추출
              *********************************************************/
              let plv_Vm = lv_Vm
              let pTrnstId = 'txTSSBC91S1'
              let pParams = {
                userEno : userInfo.userEno,
                welcomeType : lv_Vm.lv_welcomeType,
                orgNo     : userInfo.onpstOrgKndCd,
                hofOrgNo  : userInfo.onpstHofOrgNo,
                fofOrgNo  : userInfo.onpstFofOrgNo,
                comtPosx  : jsonObj.myLng,
                comtPosy  : jsonObj.myLat
              }
              
              console.log('@@@@ trnstId @@@@@', pTrnstId)        
              console.log('@@@@ pParams @@@@@', pParams)        
              lv_Vm.post(lv_Vm, pParams, pTrnstId).then(response =>  {
                if (response.body !== null && response.body !== '') {
                  plv_Vm.lv_gpsChkYn = 'Y'
                }else{
                  plv_Vm.lv_gpsChkYn = 'N'
                }
                
                console.log("@@@@@@@@@@  gpsChkYn  >> "+plv_Vm.lv_gpsChkYn)
              }).catch(function (error) {
                console.log("@@@@@@@@@@  txTSSBC91S1 error  >>")
                window.vue.error(error)
                reject('txTSSBC91S1 gps_check reject')
              })
            }
          })
        }
        resolve('txTSSBC91S1 gps_check')
      })
    },
    fn_loginInfo(lv_Vm, userInfo){
      return new Promise((resolve, reject) =>{
        /*********************************************************
        * 설명: 로그인 이력 정보 추출
        *********************************************************/
        let trnstId = 'txTSSBC91P1'
        let params = {
          userEno : userInfo.userEno,
        }
        let setTime = 0
        
        if(lv_Vm.lv_clicked){
          if(lv_Vm.lv_goodMorning == 'refresh element' || lv_Vm.lv_success == 'refresh element'){
            lv_Vm.lv_afterLogin = true
            lv_Vm.rotateRefresh()
          }
          setTime = 500
        }

        setTimeout(() => {
          lv_Vm.post(lv_Vm, params, trnstId).then(response =>  {
            if (response.body !== null && response.body !== '') {
              lv_Vm.lv_loginData = response.body
              
              if((lv_Vm.lv_loginData.inDataTp == 'Y' || lv_Vm.lv_loginData.inDataPhone == 'Y')){
                lv_Vm.lv_loginWelcomeYn = true
              }
              if((lv_Vm.lv_loginData.outDataTp == 'Y' || lv_Vm.lv_loginData.outDataPhone == 'Y' || lv_Vm.lv_loginData.cnclnData == 'Y')){
                lv_Vm.lv_loginWelcomeBackYn = true
              }

              if(lv_Vm.lv_welcomeType == 1 || lv_Vm.lv_welcomeType == 0){
                if(lv_Vm.lv_loginWelcomeYn){
                  lv_Vm.lv_goodMorning = 'on'
                }else{
                  if(lv_Vm.lv_welcomeType == 1){
                    lv_Vm.lv_goodMorning = 'refresh element'
                  }else{
                    lv_Vm.lv_goodMorning = 'off'
                  }
                }
              }else if(lv_Vm.lv_welcomeType == 2){
                if(lv_Vm.lv_loginWelcomeYn && !lv_Vm.lv_loginWelcomeBackYn){
                  lv_Vm.lv_goodMorning = 'on'
                  lv_Vm.lv_success = 'refresh element'
                }else if(!lv_Vm.lv_loginWelcomeYn && lv_Vm.lv_loginWelcomeBackYn){
                  lv_Vm.lv_goodMorning = 'off'
                  lv_Vm.lv_success = 'on'
                }else if(lv_Vm.lv_loginWelcomeYn && lv_Vm.lv_loginWelcomeBackYn){
                  lv_Vm.lv_goodMorning = 'on'
                  lv_Vm.lv_success = 'on'
                }else{
                  lv_Vm.lv_goodMorning = 'off'
                  lv_Vm.lv_success = 'refresh element'
                }
              }
              //로그인 이력이 없을 경우 새로고침 클릭했을 때
              if((lv_Vm.lv_goodMorning === 'refresh element' || lv_Vm.lv_success === 'refresh element') && lv_Vm.lv_afterLogin ){
                lv_Vm.fn_openSnackbar()
              }
            }
          }).catch(function (error) {
            window.vue.error(error)
            reject('txTSSBC91P1 loginInfo reject')
          }) 
        }, setTime)
        resolve('txTSSBC91P1 loginInfo')
      })
    },
    /*********************************************************
     * Function명: fn_openSnackbar
     * 설명: 신호등 새로고침 클릭 시 하단 팝업 생성
     *********************************************************/
    fn_openSnackbar() {
      let ms = 3000
      const element = this.$refs.homeSnackbar
      
      if(element.classList[1] == 'on'){
        clearTimeout(this.lv_setTimeOutVal)
        element.classList.remove('on')
        
        setTimeout(() => {
          this.lv_snackbarOpen = true
          element.classList.add('on')
        }, 100);
      }else{
        this.lv_snackbarOpen = true
        element.classList.add('on')  
      }

      if(localStorage.getItem('deviceType') == 'Tablet'){
        this.lv_snacbarText = this.lv_tabletSnacbarText
      }else if(localStorage.getItem('deviceType') == 'Phone'){
        this.lv_snacbarText = this.lv_phoneSnacbarText  
      }

      this.lv_setTimeOutVal = setTimeout( this.fn_openSnackbarSetTime, ms)
    },
    /*********************************************************
     * Function명: fn_openSnackbarSetTime
     * 설명: 신호등 새로고침 클릭 시 실행되는 timeOut 함수
     *********************************************************/
    fn_openSnackbarSetTime(){
      const element = this.$refs.homeSnackbar
      element.classList.remove('on') 
      this.lv_snackbarOpen = false
    },
    /*********************************************************
     * Function명: fn_ScrollBefore
     * 설명: scroll 위치 복원
     *********************************************************/
    fn_ScrollBefore() {
      this.beforeScrollTop && this.$refs.home.scrollTo(this.beforeScrollTop)
    },
    /*********************************************************
     * Function명: fn_OpenScreen
     * 설명: 열린화면 팝업 열기
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OpenScreen () {
      this.getStore('progress').dispatch('NONE')
      let lv_Vm = this
      lv_Vm.OpenScreen = lv_Vm.$bottomModal.open(OpenScreenBottomSheet, {
        listeners: {
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_Vm.OpenScreen)
          },
          onScreenMove: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.OpenScreen)
            if(pData?.rtnData) {
              if(pData.rtnData.mobslSrnId !== 'MSPSA071M' && pData.rtnData.mobslSrnId !== 'MSPPI001M') {
                lv_Vm.$router.push({name:pData.rtnData.mobslSrnId, params: {}, query: {_isMenuItem: true}})
              }else {
                lv_Vm.$router.push({name:pData.rtnData.mobslSrnId, params: {}})
              }
            }
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_CloseOpenScreenTooltip
     * 설명: 열린화면 아이콘 툴팁 닫기
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_CloseOpenScreenTooltip() {
      localStorage.setItem('READ_OPENSCREEN_TOOLTIP_MAIN', true)
      this.lv_ReadOpenScreenTooltip = true
    },
    // 코딩 종료 함수 마지막 메서드 함수 , 없음 주의
    _fn_End(){return}
  }
};
</script>
<style scoped>
.mo-system ::v-deep .mo-bottom-navigation {
  filter: none !important;
  border-top: none !important;
}
::v-deep .mo-tooltip-anchor__arrow--bottom-normal::before {
  display: none !important;
}
</style>